
import countTo from "vue-count-to";
export  default {
  name: "VisitorView",
  components: { countTo },
  props: {
    title: String,
    animationEnabled: {type: Boolean, default: () => true},
    visitorList: { type: Object, default: () => {} },
  },
  data(){
    return{
      counting: false,
    }
  },
  mounted(){
    document.addEventListener("aos:in:ecoAnimated", () => {
      this.counting = true;
      setTimeout(() => {
        this.counting = false;
      }, 3000);
    });
  },

}
