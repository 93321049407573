
import ButtonPrimary from "@/components/Button/ButtonPrimary";
import CardEvent from "@/components/CardView/CardEvent.vue";
import CardViewEventSM from "@/components/CardView/CardViewEventSM";

export default {
  name: "LandingEvent",
  components: { CardEvent, ButtonPrimary, CardViewEventSM },
  props: {
    title: String,
    eventList: { type: Array, default: () => [] },
  },
  computed: {
    events() {
      return this.eventList.slice(0, 6);
    },
  },
};
