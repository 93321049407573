
export default {
  name: "CardViewEventSM",
  props: {
    event: { type: Object, default: () => {} },
  },
  data() {
    return {};
  },
  computed: {
    title() {
      return this.event.title;
    },
    hostBy() {
      return this.event.host_by;
    },
    lang() {
      return this.$i18n.locale;
    },
    displayDate() {
      let date = this.event.start_datetime || new Date();
      return this.$moment(date).locale(this.lang).format("DD");
    },
    displayMonth() {
      let date = this.event.start_datetime || new Date();
      return this.$moment(date).locale(this.lang).format("MMM");
    },
  },
};
