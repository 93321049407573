import { render, staticRenderFns } from "./index.vue?vue&type=template&id=29cbca28"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=29cbca28&prod&lang=css"
import style1 from "./index.vue?vue&type=style&index=1&id=29cbca28&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Highlight: require('/workspace/components/Common/Highlight.vue').default,LandingWhatWeDo: require('/workspace/components/Common/LandingWhatWeDo.vue').default,LandingEcosystem: require('/workspace/components/Common/LandingEcosystem.vue').default,LandingProgram: require('/workspace/components/Common/LandingProgram.vue').default,LandingEvent: require('/workspace/components/Common/LandingEvent.vue').default,LandingPost: require('/workspace/components/Common/LandingPost.vue').default,OurPartner: require('/workspace/components/Common/OurPartner.vue').default,VisitorView: require('/workspace/components/Common/VisitorView.vue').default})
