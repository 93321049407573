
export default {
  props:{
    highlights: { type: Array, default: () => []}
  },
  data(){
    return{
      dialogPreview: false,
      preview: null,
      options: {
        autoplay: true,
        loop: true,
        perPage: 1,
        paginationEnabled: false,
        autoplayTimeout: 5000,
        perPageCustom: [[360, 1],[480, 2], [768, 3],[960,4]],
        navigationEnabled: true,
        autoplayHoverPause:true,
      },
    }
  },
  methods:{
    previewHighlight(item) {

      this.preview = Object.assign({}, item);
      this.dialogPreview = true;
    }
  },
  watch: {
    dialogPreview(v) {
      if (!v) {
        // this.preview = null;
      }
    },
  }
}
