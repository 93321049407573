
import LoadingCollapse from "../LoadingCollapse.vue";
import VideoEmbed from "@/plugins/video-embed";
export default {
  name: "DialogPreviewHighlight",
  props: {
    parent: String,
    loading: Boolean,
    visible: Boolean,
    detail: Object,
  },

  methods: {
    visibleChange(v = false) {
      this.$emit("update:visible", v);
      
    },
    shareTo(type) {
      const DOMAIN2SHARE = this.$config.SHARE_URL || window?.location?.origin;
      let url = DOMAIN2SHARE + "/program?id=" + this.$route.query.id;
      let text = this.detail.title;
      const shareURL = {
        linkedin: `https://www.linkedin.com/shareArticle?mini=true&title=${text}&url=${url}`,
        facebook: `https://facebook.com/sharer/sharer.php?u=${url}`,
        twitter: `https://twitter.com/share?url=${url}&text=${text}`,
        telegram: `https://t.me/share/url?url=${url}&text=${text}`,
      };

      if (!!shareURL[type]) window.open(shareURL[type]);
    },
  },
  computed: {
    hasVideo() {
      return this.detail?.video_embedded_url;

    },

    originUrl() {
      return this.detail?.origin_url;
    },

    shareOptions() {
      return [
        {
          text: this.$t("menu.share_to.linkedin"),
          icon: "mdi-linkedin",
          type: "linkedin",
        },
        {
          text: this.$t("menu.share_to.facebook"),
          icon: "mdi-facebook",
          type: "facebook",
        },
        {
          text: this.$t("menu.share_to.twitter"),
          icon: "mdi-twitter",
          type: "twitter",
        },
        {
          text: this.$t("menu.share_to.telegram"),
          icon: "mdi-send-circle",
          type: "telegram",
        },
      ];
    },
  },
  components: {LoadingCollapse, VideoEmbed},
};
