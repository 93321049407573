
export default {
  name: "CardEvent",
  props: {
    label: String,
    hostBy: String,
    description: String,
    datetime: String,

  },
  methods:{
    shareTo(resource, type) {
      let url = this.fileURL;
      let text = resource.name;
      const shareURL = {
        linkedin: `https://www.linkedin.com/shareArticle?mini=true&title=${text}&url=${url}`,
        facebook: `https://facebook.com/sharer/sharer.php?u=${url}`,
        twitter: `https://twitter.com/share?url=${url}&text=${text}`,
        telegram: `https://t.me/share/url?url=${url}&text=${text}`,
      };

      if (!!shareURL[type]) window.open(shareURL[type]);
    },
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    displayDate() {
      let date = this.datetime || new Date();
      return this.$moment(date).locale(this.lang).format("DD");
    },
    displayMonth() {
      let date = this.datetime || new Date();
      return this.$moment(date).locale(this.lang).format("MMM");
    },
    shareOptions() {
      return [
        {
          text: this.$t("menu.share_to.linkedin"),
          icon: "mdi-linkedin",
          type: "linkedin",
        },
        {
          text: this.$t("menu.share_to.facebook"),
          icon: "mdi-facebook",
          type: "facebook",
        },
        {
          text: this.$t("menu.share_to.twitter"),
          icon: "mdi-twitter",
          type: "twitter",
        },
        {
          text: this.$t("menu.share_to.telegram"),
          icon: "mdi-send-circle",
          type: "telegram",
        },
      ];
    },
  },
};
