
import SimpleCard from "@/components/CardView/SimpleCard.vue";
import ButtonPrimary from "@/components/Button/ButtonPrimary";

export default {
  components: { ButtonPrimary, SimpleCard },
  props: {
    title: String,
    programs: { type: Array, default: () => [] },
  },
  computed: {
    programList() {
      return this.programs.slice(0, 4);
    },
  },
};
