
import ButtonPrimary from "@/components/Button/ButtonPrimary";
import CardStakeholderCounter from "@/components/CardView/CardStakeholderCounter.vue";
export default {
  name: "LandingEcosystem",
  components: { CardStakeholderCounter, ButtonPrimary },
  props: {
    ecosystem: { type: Object, default: () => ({}) },
    items: { type: Array, default: () => [] },
    showCaption: { type: Boolean, default: () => true },
    showButton: { type: Boolean, default: () => true },
    animationEnabled: { type: Boolean, default: () => true },
  },
  data() {
    return {
      counting: false,
    };
  },
  computed: {
    showCounter() {
      return !!this.items.length;
    },
  },
  mounted() {
    document.addEventListener("aos:in:ecoAnimated", () => {
      this.counting = true;
      setTimeout(() => {
        this.counting = false;
      }, 3000);
    });
  },
  beforeDestroy() {
    window.removeEventListener("aos:in:ecoAnimated", () => {});
  },
};
