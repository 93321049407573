
export default {
  name: "OurPartner",
  props: {
    title: String,
    partnerList: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
  mounted() {},
  watch: {
    partnerList() {
      setTimeout(() => {
        this.initSlider();
      }, 100);
    },
  },
  methods: {
    initSlider() {
      this.swiperPartner = new this.$swiper(".swiperPartner", {
        slidesPerView: 2,
        spaceBetween: 10,
        loop: true,
        autoplay: { delay: 3000, disableOnInteraction: false },
        breakpoints: {
          "@0.00": { slidesPerView: 2, spaceBetween: 20 },
          "@0.75": { slidesPerView: 3, spaceBetween: 30 },
          "@1.00": { slidesPerView: 4, spaceBetween: 40 },
          "@1.50": { slidesPerView: 5, spaceBetween: 50 },
        },
      });
    },
  },
  beforeDestroy() {
    this.swiperPartner?.destroy();
  },
};
