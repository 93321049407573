
import countTo from "vue-count-to";
export default {
  name: "CardStakeholderCounter",
  components: { countTo },
  props: {
    label: String,
    refresh: Boolean,
    amount: { type: Number, default: 0 },
    icon: {
      type: String,
      default: "",
    },
    color: { type: String, default: "#93ccfc" },
    compact: Boolean,
  },
  computed: {
    defaultIcon() {
      return require("@/static/icons/ecosystem/Rocket.svg?raw");
    },
  },
  watch: {
    refresh(v) {
      if (v) {
        this.$refs.numbCountTo.start();
        return;
      }
    },
  },
};
