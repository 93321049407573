

import countTo from "vue-count-to";

export default {
  name:"CardVisitorCounter",
  props: {
    label: String,
    refresh: Boolean,
    amount: { type: Number, default: 0 },

  },
  components: { countTo },
  watch: {
    refresh(v) {
      if (v) {
        this.$refs.numbCountTo.start();
        return;
      }
    },
  },
}
