
import anime from "animejs";
import ButtonPrimary from "@/components/Button/ButtonPrimary";
import SimpleCard from "@/components/CardView/SimpleCard.vue";

export default {
  name: "LandingPost",
  components: { ButtonPrimary, SimpleCard },
  props: {
    posts: { type: Array, default: () => [] },
  },
  data() {
    return {
      num: 60,
    };
  },
  computed: {
    postList() {
      return this.posts.slice(0, 4);
    },
    vw() {
      if (!process.browser) return 0;
      return Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
    },
    vh() {
      if (!process.browser) return 0;
      return Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );
    },
  },
  methods: {
    getURL(item) {
      if (!item.category?.slug) return "";
      return `/resource/category/${item.category.slug}/${item.id}`;
    },
    starryNight() {
      anime({
        targets: ["#sky2 .star"],
        opacity: [
          {
            duration: 700,
            value: "0",
          },
          {
            duration: 700,
            value: "1",
          },
        ],
        easing: "linear",
        loop: true,
        delay: (el, i) => 50 * i,
      });
    },
    randomRadius() {
      return Math.random() * 0.7 + 1;
    },
    getRandomX() {
      return Math.floor(Math.random() * Math.floor(this.vw)).toString();
    },
    getRandomY() {
      return Math.floor(Math.random() * Math.floor(this.vh)).toString();
    },
  },
  mounted() {
    this.starryNight();
  },
};
